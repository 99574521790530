import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PagePrestamos = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Saca tu préstamo' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Préstamos personales de hasta $2.000.000: ¡Obtén el impulso financiero que necesitas en minutos! 💸'
          subheader='Solicita en 100% online y elegí entre diversas opciones para conseguir el préstamo perfecto para vos.'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-personales">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicitá tu Préstamo 💰 </Button>
        </Link>
        <Link to="/prestamos-personales">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $300.000 💸 </Button>
        </Link>
        <Link to="/prestamos-personales">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $150.000 🤑 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
         
¡Descubre la forma más rápida y sencilla de obtener un préstamo personal de hasta $500.000 de manera 100% online y con múltiples opciones para elegir! 
En un mundo cada vez más conectado, las soluciones financieras se adaptan a tus necesidades, brindándote la comodidad y flexibilidad que mereces.
          '
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          Encontrar el préstamo perfecto para ti nunca ha sido tan fácil. Imagina tener acceso a una amplia variedad de ofertas de préstamos personales, todas en un solo lugar.
           Ya no tendrás que navegar sin rumbo en busca de la mejor opción. Ahora, con nuestra plataforma en línea, puedes comparar diferentes prestamistas y elegir la oferta que mejor se ajuste a tus necesidades y capacidades.
          
          
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='

          ¿Necesitas ese impulso financiero para concretar tus proyectos? Ya sea que desees remodelar tu hogar, emprender un nuevo negocio o cubrir gastos imprevistos, nuestros préstamos personales de hasta $500.000 están diseñados para ayudarte a alcanzar tus metas. Además, la solicitud y el proceso de aprobación son completamente digitales, lo que significa que no tendrás que hacer filas interminables ni presentar una montaña de documentos.
            <br><br>
          ¿Cómo funciona? Es simple. Accede a nuestra plataforma en línea y completa un breve formulario con tus datos personales y financieros. Luego, nuestra tecnología avanzada se encargará de analizar tu solicitud y buscar las mejores opciones de préstamos disponibles en el mercado. Podrás comparar tasas de interés, plazos de pago y condiciones generales para tomar una decisión informada.
          <br><br>
          Una vez que hayas seleccionado la oferta que más te convenga, podrás enviar tu solicitud con tan solo unos clics. Nuestro equipo de expertos estará allí para guiarte en cada paso del camino y responder todas tus dudas. Además, garantizamos la seguridad y confidencialidad de tus datos, brindándote la tranquilidad que necesitas durante todo el proceso.
          <br><br>
          Lo mejor de todo es que el proceso es rápido. En cuestión de minutos, podrás obtener una respuesta sobre la aprobación de tu préstamo. Si todo está en orden, recibirás el monto solicitado en tu cuenta bancaria en un plazo mínimo. ¡Sin complicaciones ni demoras!
          <br><br>
          No dejes que los obstáculos financieros te impidan alcanzar tus sueños. Con nuestros préstamos personales de hasta $500.000, podrás hacer realidad tus proyectos y tener el control total de tus finanzas. La comodidad de solicitar en línea, la posibilidad de comparar múltiples ofertas y la agilidad en el proceso son solo algunas de las ventajas que te ofrecemos.
          <br><br>
          No pierdas más tiempo. Accede a nuestra plataforma y descubre todas las opciones disponibles para ti. ¡Obtén ese préstamo personal de hasta $500.000 y haz realidad tus metas hoy mismo!

          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PagePrestamos
